$ui-01: #FAFAFA;
$ui-02: #1D1D1D;
$ui-03: #1400FF;
$ui-04: #282828;

$text-01: #FAFAFA;
$text-02: #1D1D1D;
$text-03: #0047FF; 
$text-disabled: #545454;
$text-hover: #DDDDDD;

$interactive-01: #1400FF;

$spacing-01: 1rem;
$spacing-02: 2rem;

$highlight-01: #FF0000;

@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf')
}

* {
    font-family: Poppins;
}

body {
    margin: 0px;
    font-size: 18px;
}

.project-title {
    margin-top: 2rem;
}

h1 {
    font-size: 48px !important;
    line-height: 4rem;
    font-weight: 600 !important;
            
    @media (max-width: 480px) {
        font-size: 24px !important;
        text-align: left !important;
        line-height: 2.2rem;
    }
}

h2 {
    font-size: 24px !important;
    padding-top: 2rem;
    text-align: left !important;
}

h3 {
    font-size: 18px !important;
    text-align: left !important;
    text-decoration: underline 1px $ui-04;

}

h4 {
    font-weight: lighter;
    font-style: italic;
    padding-bottom: 1.5rem;
    text-align: left !important;
}

h5 {
    font-size: 14px !important;
    font-weight: 100;
    text-align: center;
    color: #525252;
    padding-top: .5rem;
}

h6 {
    font-weight: bold !important;
    color: #5D5D5D;
    text-align: left;
}

.summary-title {
    font-style: italic;
}

p {
    text-align: left !important;
}

ol {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    text-align: left !important;
}

li {
    text-align: left !important;
    overflow: wrap;
    padding-bottom: .5rem;

    :last-child {
        padding-bottom: 0px;
    }
   
}

button {
    font-weight: normal !important;
    border-radius: 0 !important;
    padding: 1rem !important;
    height: 2.5rem !important;
    border: 1px solid transparent !important;

    &:focus {
        border: 1px dashed $ui-02 !important;
        box-shadow: none !important;
    }
}

a {
    color: $text-03 !important;

    &:hover {
        color: $text-03 !important;
    }

    &:focus-visible {
        outline: none !important;
    }

    &:focus {
        box-shadow: none !important;
        border: 1px dashed $text-03; 
    }
}

.container {
    &__solid-rectangle {
        padding: $spacing-01;
        border-radius: 10px;
        margin: -1rem;
        
        @media (max-width: 480px) {
            width: 100%;
        }
    }
}

img {
    image-rendering: crisp-edges;
}

img.icon-32 {
    width: 32px;
    height: 32px;
}

.chakra-accordion {
    div {
        font-size: 18px !important;
    }

    button {
        height: 100% !important;
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }       
}

.link-button {
    &__primary {
        background-color: $ui-01 !important;
        color: $ui-02;
        border-radius: 5px !important;
        border: 1px solid $interactive-01 !important;
    
        &:hover {
            box-shadow: 1rem 0rem 0px 0px $interactive-01;
        }
        &:focus {
            box-shadow: 1rem 0rem 0px 0px $ui-02 !important;
        }
    }
    &__secondary {
        background-color: $ui-02 !important;
        color: $ui-01;
        border-radius: 5px !important;
        border: 1px solid $text-03 !important;
    
        &:hover {
            box-shadow: 1rem 0rem 0px 0px $text-03;
        }
        &:focus {
            box-shadow: 1rem 0rem 0px 0px $ui-01 !important;
            border: 1px solid $ui-01 !important;
        }
    }
}

.horizontal-scrolling-container {
    height: 80vh;
    width: 100%;
    overflow-x: scroll;
}