@import '../../App.scss';

.hero {
    &__background {
        width: 100%;
        top: 0;
        z-index:-1;
        background-color: $ui-01;
        scroll-snap-align: start;

        .react-p5 {
            height: 100%;
        }

        canvas {
            width: 100% !important;
            height: 100% !important;
        }   
    }    
}
