@import '../../App.scss';

.yt-container {
  position: relative;
  background-color: transparent;
 
  height: 80vh;
  align-items: flex-start;
  padding-top: 0;
  width: 70%;

  @media (max-width: 768px) {
    height: 60vh;
    align-items: flex-start;
    padding-top: 0;
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    height: 80vh;
    align-items: flex-start;
    padding-top: 0;
    width: 70%;
  }


  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}