@import '../../App.scss';

.carousel {
    overflow: hidden;
    position:relative;
    z-index: 9;

    &__inner {
        white-space: nowrap;
        transition: transform 0.3s;
    }

}


.carousel-item {
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: $ui-04;
    color: $text-02;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (max-width: 480px) {
        height: 60vh;
        align-items: flex-start;
        padding-top: 0;
    }

    img {
        height: 100%;

        @media (max-width: 480px) {
            height: auto;
            width: 100%;
        }
    }
}

.arrow-right {
    position: absolute !important;
    right: 3rem;
    top: calc(50% - 1rem);
    z-index: 999;
    border-radius: 10px !important;
    width: 2rem !important;
    transform: rotate(45deg);
    transition: all .2s ease-in-out;
    color: $ui-01;
    border: 1px solid $ui-01 !important;
    background-color: $ui-04 !important;

    &__icon {
        transform: rotate(-45deg);
    }

    &:hover {
        box-shadow: .5rem -.5rem 0px 0px $ui-01;
    }
    &:focus {
        box-shadow: .5rem -.5rem 0px 0px $interactive-01 !important;
    }

    @media (max-width: 480px) {
        top: calc(100% - 3.5rem);
    }
}

.arrow-left {
    position: absolute !important;
    left: 3rem;
    top: calc(50% - 1rem);
    z-index: 999;
    border-radius: 10px !important;
    width: 2rem !important;
    color: $ui-01;
    border: 1px solid $ui-01 !important;
    background-color: $ui-04 !important;
    transform: rotate(45deg);
    transition: all .2s ease-in-out;

    &__icon {
        transform: rotate(-45deg);
    }

    &:hover {
        box-shadow: -.5rem .5rem 0px 0px $ui-01;
    }
    &:focus {
        box-shadow: -.5rem .5rem 0px 0px $interactive-01 !important;
    }

    @media (max-width: 480px) {
        top: calc(100% - 3.5rem);
    }
}