@import '../../../../App.scss';

.tile {
    scroll-snap-align: start;
    height: 100vh;
    z-index: 8;
    color: $text-02;

    @media (max-width: 480px) { 
        height: 100%;
    }

    &__order-imprint {
        position: absolute;
        height: 100%;
        width: 50%;
        text-align: left;
        z-index: -1;
        color: $interactive-01;
        opacity: .05;
        font-size: 100vh;
        line-height: 100vh;
        user-select: none;
    }

    &__transition {
        transition: all .2s ease-in-out;
    }

    &__image-container {
        height: 100%;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-out;
        // background-color: $interactive-01;
        border-radius: 5px;


        
        img {
            max-height: 500px;
            transition: all .2s ease-out;

            &:hover {
                filter: drop-shadow(1rem 0px $interactive-01);
            }
        }

        // img {   
        //     transition: all .3s ease-out;
            
        //     &:hover {
        //         transform: scale(1.03);
        //     }
        //     &:active {
        //         box-shadow: 1rem 0px 0px 0px $ui-02 !important;
        //         border: 1px dashed $ui-02;
        //     }
        // }

        &__click-area {
            cursor: pointer;
        }
    }
}