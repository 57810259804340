@import '../../App.scss';

.section {
    &__line {

        &__top {
            margin-bottom: 1rem;
            border-top: 1px solid $ui-02;
        }

        &__bottom {
            margin-top: 1rem;
            margin-bottom: 4rem;
            border-top: 1px solid $ui-02;
        }
    }
}