@import '../../App.scss';

.disable-scroll {
    overflow-y: hidden;
}

.lightbox-zoomed-false {
    cursor: zoom-in;
}

.lightbox-zoomed-true {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 999;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: zoom-out;
    overflow-y: scroll;

    &__image {
        margin: auto;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}