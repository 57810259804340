@import '../../App.scss';

.home {
    
    @media (min-width: 480px) {
       
        scroll-snap-type: mandatory;
        scroll-snap-type: y mandatory;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-behavior: smooth;
        
        &__fix {
            background-color: $ui-01;
            height: calc(400vh + 5rem);
        }
    }
    
    button {
        font-size: 14px !important;
    }
}

.progress {
    &__container{
        position: fixed;
        z-index: 999999;
        top: 50%;
        left: 1rem;
        transition: all ease-in-out 0.2s;
    }

    &__circle {
        border: 1px solid $ui-02;
        width: .5rem;
        height: .5rem;
        border-radius: 1rem;
        margin-bottom: .25rem;
        transition: all ease-in-out 0.2s;
    }
}