@import '../../App.scss';

.page-loader {
    &__container {
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100vh;
        background-color: $ui-01;
        overflow: hidden;
    }

    &__loading-bar {
        background-color: $interactive-01;
        height: 1rem;
        transition: all 2s ease-in-out;
    }

    &__loader {
        div {
            background-color: $interactive-01;
            height: 1rem;
        }
    }
}