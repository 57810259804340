@import '../../App.scss';

.outline-container {
    bottom: calc(57px + 2rem);
    position:fixed;
    width:33.3333333%;
    left: calc((100% / 12) - 1rem);

    @media only screen and (max-width: 820px) {
       display:none !important;
    }

    .outline {
        color: $text-disabled;

        h4 {
            padding-bottom: 1rem;
        }

        button{
            background-color: transparent;
            justify-content: flex-start; 
            border-radius: 5px !important;
            border: none !important;

            &:hover {
                color: $text-hover;
            }
        }

        button#current-section {
            background-color: transparent;
            color:$text-01;
            justify-content: flex-end; 
            border: 1px solid transparent !important;
            
        }

        #outline__curr-section {
            color: $interactive-01;
            // background-color: $ui-04;
        }
    }
}