@import '../../../App.scss';

.hero {
    height: 100%;
    width: 100%;
    scroll-snap-align: start;
    height: 100vh;
    position: relative;

    h1 {
        font-weight: bold !important;
        padding-bottom: 4rem;
        font-size: 98px !important;
        
        @media (max-width: 480px) {
            padding-bottom: 1rem;
            font-size: 48px !important;
        }
    }

    &__container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        color:$text-02;
        z-index: 8;
        
        h2 {
            padding-top: 0px;
        }
    }

    &__logo-container {
        display: inline-block;
        margin-right: 1rem;
        height:100%;
    }
    
    &__intro-container {
        justify-content: center;
        flex-direction: column;
        height: 100%;
        margin-left: calc(100%/12);
        padding-right: .5rem;
        width: 33.33333%;

        &__intro {
            display: inline-block;
        }

        @media (max-width: 480px) {
            padding-right:0;
            margin-left: 1rem;
            width: calc(100% - 2rem);
            margin-top: 8rem;
        }

        .hero__hello{
            margin-bottom: 1rem;
        }
    }

    &__headshot {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: calc(100%/3);
        margin: auto 0;
        margin-right: calc(100%/8 - .5rem);
        border-radius: 5px;

        @media (max-width: 480px) {
            top: 4rem;
            bottom: auto;
            left: 0;
            width: 100%;
            height: 15rem;
            object-fit: cover;
            border-radius: 0;
        }
    }

    &__anim-tooltip {
        z-index: 100;
        margin-left: 4rem;
        margin-top: 5rem;
    }

    &__caption {
        color: $ui-02;
        font-size: 12px;
    }
}
