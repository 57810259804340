@import '../../../App.scss';

.projects {
    &__container {
        min-height: 10rem;
        background-color: $ui-01;
    }
    
    &__read-more-button {
        background-color: $ui-01 !important;
        color: $ui-02;
        border-radius: 5px !important;
        border: 1px solid $interactive-01 !important;

        &:hover {
            box-shadow: 1rem 0rem 0px 0px $interactive-01;
        }
        &:focus {
            box-shadow: 1rem 0rem 0px 0px $ui-02 !important;
        }
    
    }

    &__fun-box{
        margin-top: 1rem; 
        margin-left: auto; 
        width: 1rem;
        height: 1rem; 
        border: 1px solid $interactive-01; 
        border-radius: 5px; 
    }

    &__austin-cold-image {
        max-height: 90vh;
    }
}